// import Tooltip from 'components/Tooltip';
import RangeFilter, { DateRangeFilter } from 'components/RangeFilter';
import {
  TableCellPlatformProductLink,
  TableCellSellerLink,
  TableCellWecheckProductLink,
} from 'components/TableCellAdvertisingLinks';
import TableCellAmountWithPercent from 'components/TableCellAmountWithPercent';
import TableCellBoolean from 'components/TableCellBoolean';
import TableCellCategoriesHighlighted from 'components/TableCellCategoriesHighlighted';
import TableCellDiscountTooltip from 'components/TableCellDiscountTooltip';
import TableCellFilterLink from 'components/TableCellFilterLink';
import TableCellImage from 'components/TableCellImage';
import TableCellImageTooltip from 'components/TableCellImageTooltip';
import TableCellPlatformLink from 'components/TableCellPlatformLink';
import TableCellProductLink from 'components/TableCellProductLink';
import TableCellProductsPriceRangeLink from 'components/TableCellProductsPriceRangeLink';
import {
  TableCellProductVariation,
  TableCellProductVariationLink,
} from 'components/TableCellProductVariationLink';
import TableCellSeoProductCheckAppearanceCell from 'components/TableCellSeoProductCheckAppearanceCell';
import TableCellWithSelect from 'components/TableCellWithSelect';
import TableHeaderCell from 'components/TableHeaderCell';
import TableHeaderFilterSearch from 'components/TableHeaderFilterSearch';
import TableInnerDiscountChanger from 'components/TableInnerDiscountChanger';
import TableInnerPriceChanger from 'components/TableInnerPriceChanger';
import TableInnerProductName from 'components/TableInnerProductName';
import TableListingWithPositionCell from 'components/TableListingWithPositionCell';
import { TABLE_TYPE } from 'constants';
import { AG_GRID_LOCALE_RU } from 'helpers/aggridLocaleRu';
import { HIERARCHY_KEY } from 'helpers/generateGridRowData';
import SetStatusFilter from '../components/SetFilter/SetStatusFilter';
import TableCellInfoTooltip from '../components/TableCellAdvertisingLinks/TableCellInfoTooltip';
import TableCellDate from '../components/TableCellDate';
import { TableCellSellersFrontTooltip } from '../components/TableCellSellersFrontTooltip';
import TableCellStatus from '../components/TableCellStatus';
import TableInnerPredict from '../components/TableInnerPredict';
import TableInnerPrimeCostChanger from '../components/TableInnerPrimeCostChanger';
import TableMonitoringPhrasesTableCell from '../components/TableMonitoringPhrasesTableCell';
import TableSeoOrdersChartCell from '../components/TableSeoOrdersChartCell';
import TableSeoPhraseNestedCell from '../components/TableSeoPhraseNestedCell';
import { TableSidebarTemplatesPanel } from '../components/TableSidebarTemplatesPanel';

const getToolPanels = (filterColumnState) => {
  const tools = [
    {
      id: 'columns',
      labelDefault: 'Настроить колонки',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
      },
    },
  ];

  if (!filterColumnState) {
    tools.push({
      id: 'filters',
      labelDefault: 'Фильтры',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        test: 'test',
      },
    });
  }
  tools.push({
    id: 'templates',
    labelDefault: 'Шаблоны настроек',
    labelKey: 'templates',
    iconKey: 'templates',
    toolPanel: TableSidebarTemplatesPanel,
    toolPanelParams: {},
  });
  return tools;
};

const getRowClass = (params) => {
  if (params.data?.highlighted) {
    return 'row--highlighted';
  }
};

const getRowStyle = (params) => {
  return params.data?.style;
};

const cell = (text, styleId) => ({
  styleId: styleId,
  data: {
    type: /^\d+$/.test(text) ? 'Number' : 'String',
    value: String(text),
  },
});

export const PER_PAGE = 50;

const getCells = (params, columnDefsNested) => {
  const nestedHeaderCells = columnDefsNested.map((column) => cell(column?.headerName, 'header'));

  const nestedBodyCells = params.node.data.children.map((record) => {
    const bodyCells = columnDefsNested.map((column) => cell(record?.[column?.field], 'body'));

    return [cell(''), ...bodyCells];
  });

  return [[cell(''), ...nestedHeaderCells]].concat(nestedBodyCells, [[]]);
};

const defaultExportParams = (columnDefsNested, isNestedIncluded) => ({
  getCustomContentBelowRow: isNestedIncluded
    ? (params) => getCells(params, columnDefsNested)
    : undefined,
});
export const CSS_CLASS_FOR_NESTED_HEADER_CELL = 'custom-header-cell--nested';
const ROW_HEIGHT = 38;
export const HEADER_HEIGHT = 40;

const loadingTemplate = '<span class="table-loading"/>';
const noDataTemplate = (noRowsText) =>
  `<div class="flex justify-center items-center bg-gray-100 absolute inset-0" style="top: ${HEADER_HEIGHT}px"><div class="text-base text-green mr-5 pr-6 border-r border-gray-250 py-1"><img src="/icons/empty-table.svg" alt="Icon Empty Table" /></div><div class="text-sm text-green text-left">${noRowsText}</div></div>`;

const getRowHeightDefault = (params) => {
  if (params.node && params.node.detail) {
    const gridSizes = params.api.getSizesForCurrentTheme();

    const offset = 40;
    const children =
      params?.node?.detailGridInfo?.api?.rowModel?.rowsToDisplay || params.data.children || [];
    const allDetailRowHeight = children?.length * gridSizes.rowHeight;
    return allDetailRowHeight + ((gridSizes && gridSizes.headerHeight) || 0) + offset;
  }
};

const setRowWidth = ({ masterGridParams, params }) => {
  const detailId = masterGridParams?.node?.id;
  const width = params.columnApi.columnModel.bodyWidth;

  const style = document.createElement('style');
  style.textContent = `
            [row-id='${detailId}'] .ag-details-grid {
              width: ${width + 6}px;
            }
          `;
  document.head.appendChild(style);
};

// export const DIRTY_HOOK_TO_SHOW_TOOLTIP = 'hook-to-show-tooltip-for-disabled-filter-settings';
export const AMOUNT_WITH_PERCENT_TABLE_CELL_NAME = 'tableCellAmountWithPercent';
export const DISABLED_FILTER_TOOLTIP =
  'Чтобы использовать эти фильтры выберите один из фильтров:\nпо категории, бренду или продавцу';

const components = {
  // customTooltip: Tooltip,
  imageTooltip: TableCellImageTooltip,
  tableCellInfoTooltip: TableCellInfoTooltip,
  tableCellSellersFrontTooltip: TableCellSellersFrontTooltip,
  discountTooltip: TableCellDiscountTooltip,
  rangeFilter: RangeFilter,
  statusFilter: SetStatusFilter,
  customRangeDateFilter: DateRangeFilter,
  searchFilter: TableHeaderFilterSearch,
  productLinkTableCell: TableCellProductLink,
  productVariationLinkTableCell: TableCellProductVariationLink,
  productVariationTableCell: TableCellProductVariation,
  platformLinkTableCell: TableCellPlatformLink,
  platformProductLinkTableCell: TableCellPlatformProductLink,
  wecheckProductLinkTableCell: TableCellWecheckProductLink,
  sellerLinkTableCell: TableCellSellerLink,
  platformImageTableCell: TableCellImage,
  filterLinkTableCell: TableCellFilterLink,
  tableCellHighlight: TableCellCategoriesHighlighted,
  tableCellSeoPhraseNested: TableSeoPhraseNestedCell,
  tableCellSeoProductCheckAppearance: TableCellSeoProductCheckAppearanceCell,
  tableCellBoolean: TableCellBoolean,
  dateExpandTableCell: TableCellDate,
  [AMOUNT_WITH_PERCENT_TABLE_CELL_NAME]: TableCellAmountWithPercent,
  tableCellWithSelect: TableCellWithSelect,
  productsPriceRangeLink: TableCellProductsPriceRangeLink,
  primeCostChanger: TableInnerPrimeCostChanger,
  innerTableProductName: TableInnerProductName,
  innerTableStatus: TableCellStatus,
  innerPredictCellRenderer: TableInnerPredict,
  innerPriceChanger: TableInnerPriceChanger,
  innerDiscountChanger: TableInnerDiscountChanger,
  monitoringPhrases: TableMonitoringPhrasesTableCell,
  listingWithPositionTableCell: TableListingWithPositionCell,
  seoChartOrdersRub: TableSeoOrdersChartCell,
};

const cellClassRules = {
  'text-right': (params) => !params.colDef?.cellRendererParams?.suppressRightAlign,
  truncate: (params) => params.colDef?.cellRendererParams?.truncate,
  'text-overflow-hidden-left': (params) => params.colDef?.cellRendererParams?.overflowHiddenLeft,
  'text-green': (params) =>
    params.colDef?.cellRendererParams?.percentageColored &&
    (params.value > 0 || params.value?.[0] === '+'),
  'text-red': (params) =>
    params.colDef?.cellRendererParams?.percentageColored &&
    (params.value < 0 || params.value?.[0] === '-'),
};

export const generateGridConfig = ({
  hierarchyColumn,
  noRowsText = null,
  columnDefsNested,
  filterColumnState,
  autoSizeNestedColumns,
  isNestedIncluded,
  isLoading,
  hidePagination,
  showFilterHiddenTooltip,
  asyncGetDetailRowData,
  showSidebar,
  type,
  getRowHeight,
  // gridOptions,
}) => {
  const getDetails = (masterGridParams, index = 1) => ({
    detailGridOptions: {
      columnDefs: columnDefsNested,
      rowClass: 'row',
      rowHeight: ROW_HEIGHT,
      tooltipShowDelay: 0,
      headerHeight: HEADER_HEIGHT,
      masterDetail: type === TABLE_TYPE.SEO_HINTS_REQUESTS || type === TABLE_TYPE.SEO_SMART_WORDS,
      detailRowAutoHeight: true,
      groupDefaultExpanded: type === TABLE_TYPE.SEO_HINTS_REQUESTS && index === 1 ? 1 : null,
      detailCellRendererParams:
        (type === TABLE_TYPE.SEO_HINTS_REQUESTS || type === TABLE_TYPE.SEO_SMART_WORDS) &&
        index <= 3
          ? getDetails(masterGridParams, index + 1)
          : undefined,
      defaultColDef: {
        headerClass: `custom-header-cell ${CSS_CLASS_FOR_NESTED_HEADER_CELL}`,
        headerComponent: TableHeaderCell,
        resizable: true,
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        headerTooltip: null,
        cellClassRules,
      },
      overlayNoRowsTemplate:
        noRowsText && !isLoading ? `<div />` /*noDataTemplate(noRowsText)*/ : loadingTemplate,
      overlayLoadingTemplate: loadingTemplate,
      components,
      onFirstDataRendered: (params) => {
        autoSizeNestedColumns && params.columnApi.autoSizeColumns(autoSizeNestedColumns);

        setRowWidth({ masterGridParams, params });
      },
      onFilterChanged: () => {
        masterGridParams.api.resetRowHeights();
      },
      localeText: AG_GRID_LOCALE_RU,
    },
    getDetailRowData: async (params) => {
      // если приходит asyncGetDetailRowData, получаем данные из неё
      // если не приходит, берём данные из data.children в new Promise
      // getResultChildren специально сделана асинхронной,
      // чтобы в нужное время отработал resetRowHeights
      // при любом способе получения данных
      const getResultChildren = async () => {
        if (asyncGetDetailRowData) {
          return await asyncGetDetailRowData(params);
        } else {
          return new Promise((resolve) => {
            resolve(params.data.children);
          });
        }
      };
      let resultChildren = await getResultChildren();
      if (type === TABLE_TYPE.SEO_HINTS_REQUESTS) {
        setTimeout(() => {
          params.successCallback(resultChildren);
          masterGridParams?.api.resetRowHeights();
        }, 700);
      } else {
        params.successCallback(resultChildren);
        masterGridParams?.api.resetRowHeights();
      }
    },
  });
  return {
    domLayout: 'autoHeight',
    groupDefaultExpanded: !columnDefsNested.length
      ? 1
      : type === TABLE_TYPE.SEO_HINTS_REQUESTS || type === TABLE_TYPE.SEO_HINTS_REQUESTS_INNER
      ? 3
      : null,
    enableRangeSelection: true,
    rowHeight: ROW_HEIGHT,
    headerHeight: HEADER_HEIGHT,
    rowClass: 'row',
    rowSelection: 'multiple',
    maxHeight: 200,
    treeData: !!hierarchyColumn,
    ...(hierarchyColumn && {
      autoGroupColumnDef: hierarchyColumn,
    }),
    getDataPath: (data) => (data?.[HIERARCHY_KEY] ? [...data?.[HIERARCHY_KEY]] : [data]),
    tooltipShowDelay: 200,
    excludeChildrenWhenTreeDataFiltering: true,
    defaultColDef: {
      headerClass: 'custom-header-cell',
      cellClassRules,
      // tooltipComponent: 'customTooltip',
      resizable: true,
      width: 150,
      suppressMenu: false,
      filter: false,
      floatingFilter: false,
      enablePivot: false,
      headerComponent: TableHeaderCell,
      headerComponentParams: {
        rangeOnBack: true,
      },
      // headerTooltip: DIRTY_HOOK_TO_SHOW_TOOLTIP,
      headerTooltip: showFilterHiddenTooltip ? DISABLED_FILTER_TOOLTIP : '',
      tooltipMouseTrack: false,
      // tooltipField: 'sold_number', //without this param cell tooltip doesn't work
      menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    },
    suppressRowHoverHighlight: false,
    showLoadingOverlay: true,
    overlayNoRowsTemplate: noRowsText && !isLoading ? noDataTemplate(noRowsText) : loadingTemplate,
    overlayLoadingTemplate: loadingTemplate,
    pagination: !hidePagination,
    paginationPageSize: PER_PAGE,
    suppressPaginationPanel: true,
    statusBar: {
      statusPanels: [
        {
          key: 'statusBarCompKey',
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'avg', 'max', 'min', 'count'],
          },
        },
      ],
    },
    localeText: AG_GRID_LOCALE_RU,
    animateRows: true,
    sideBar: showSidebar
      ? {
          toolPanels: getToolPanels(filterColumnState),
        }
      : null,
    icons: {
      templates:
        '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" class="mb-1">\n' +
        '<path d="M6.49963 4H11.9996" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>\n' +
        '<path d="M0.999634 10H7.99963" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>\n' +
        '<rect x="0.749634" y="1.75" width="4.5" height="4.5" rx="2.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>\n' +
        '<rect x="8.74963" y="7.75" width="4.5" height="4.5" rx="2.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>\n' +
        '</svg>',
    },
    components,
    getRowClass,
    getRowStyle,
    masterDetail: columnDefsNested.length > 0,
    embedFullWidthRows: true,
    defaultCsvExportParams: defaultExportParams(columnDefsNested, isNestedIncluded),
    defaultExcelExportParams: defaultExportParams(columnDefsNested, isNestedIncluded),
    excelStyles: [
      {
        id: 'header',
        interior: {
          color: '#aaaaaa',
          pattern: 'Solid',
        },
      },
      {
        id: 'body',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
      },
    ],
    getRowHeight: (params) =>
      getRowHeight ? getRowHeight(params) : getRowHeightDefault({ ...params, type }),
    ...(columnDefsNested.length > 0 && {
      detailCellRendererParams: getDetails(),
    }),
  };
};
