import TableIsPromotionalFilter from 'components/TableIsPromotionalFilter';
import { useParams } from 'react-router-dom';

const ProductTableHeaderExtraFilters = () => {
  let filters = [];
  const { tab } = useParams();

  if (tab === 'listing') {
    filters.push(<TableIsPromotionalFilter key={'listing'} />);
  }

  if (filters.length === 0) {
    return null;
  }
  return <div className="mx-4 py-4 border-t border-gray-200">{filters}</div>;
};

export default ProductTableHeaderExtraFilters;
